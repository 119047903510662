
import { defineComponent, computed, onMounted } from 'vue'
import { SHOP_STATUS } from '@/api/user/user.type'
import { userCurrency } from '@/use/userCurrency'
import dayjs from '@/utils/dayjs'
import { useRouter } from 'vue-router'
import { useShopStore } from '@/pinia/shop/shop.pinia'
import { useUserStore } from '@/pinia/user/user.pinia'

export default defineComponent({
	name: 'Suspend',
	setup() {
		const shopStore = useShopStore()
		const userStore = useUserStore()
		const router = useRouter()
		const billPaymentDetail = computed(() => shopStore.billPayment)
		const { currencyMoneyDigits } = userCurrency()

		onMounted(async () => {
			const shopId = shopStore.selectedShopId as string
			const params = {
				shopId: shopId,
				isShowDialog: false,
			}
			await shopStore.getBillPayment(params) // Get bill payment
			// shopStore.updateIsDialogBillPayment(false)
			if (userStore?.permission?.shopStatus === SHOP_STATUS.ACTIVE) {
				return router.push('/dashboard')
			}
		})
		return {
			billPaymentDetail,
			currencyMoneyDigits,
			dayjs,
		}
	}
})
